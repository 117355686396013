import { React, useEffect } from "react";
import Header from "../Component-All-Pages/Header";
import Footer from "../Component-All-Pages/Footer";
import CenterBlockHomePage from "../Component-HomePage/CenterBlock-HomePage";
import "../../Styles/HomePage.css";
import RightBlockHomePage from "../Component-HomePage/RightBlock-HomePage";
import LeftBlockHomePage from "../Component-HomePage/LeftBlock-HomePage";

function HomePage() {
  useEffect(() => {
    // titre de la page
    document.title = "King Fury Pronos - Meilleur site de paris sportif";
  }, []);
  return (
    <div className="HomePage">
      <Header />
      <div className="container-fluid bodyHomePage">
        <div className="row">
          <div className="col-md-3">
            <LeftBlockHomePage />
          </div>
          <div className="col-md-6 centerBlockPage">
            <CenterBlockHomePage />
          </div>
          <div className="col-md-3">
            <RightBlockHomePage />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
