import React, { useEffect, useState } from "react";
import "../../Styles/Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 9200) {
        // ajustez la valeur selon vos besoins
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function copyCodeToClipboard(text) {
    text = "Zen142";
    navigator.clipboard
      .writeText(text)
      .then(function () {
        document.getElementById("btnCopyFooterBlock").style.display = "none";
        document.getElementById("succesBtnCopyFooterBlock").style.display =
          "block";
      })
      .catch(function (error) {});
  }

  return (
    <>
      <div className="footer">
        {/* block qui apparait lorsqu'on scroll vers le bas */}
        {visible && (
          <div className="blockCopyCode" id="blockCopyCode">
            <img src="Img/logo/logo1xbet2.png" alt="1xbet" />
            <div class="rating">
              <span class="star">&#9733;</span>
              <span class="star">&#9733;</span>
              <span class="star">&#9733;</span>
              <span class="star">&#9733;</span>
              <span class="star">&#9733;</span>
            </div>
            <p>Des offres de bienvenue pour tous</p>
            <p>code bonus</p>
            <div
              className="divTextToCopy"
              id="divTextToCopy"
              style={{ display: "flex" }}
            >
              <input
                className="inputTextToCopy"
                id="inputTextToCopy"
                type="text"
                value="Zen142"
                disabled
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                class="bi bi-copy"
                viewBox="0 0 16 16"
                style={{
                  color: "white",
                  marginLeft: "60px",
                  cursor: "pointer",
                }}
                onClick={copyCodeToClipboard}
                id="btnCopyFooterBlock"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                class="bi bi-check2-all"
                viewBox="0 0 16 16"
                style={{ color: "gold", marginLeft: "60px", display: "none" }}
                id="succesBtnCopyFooterBlock"
              >
                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
              </svg>
            </div>
            <div className="blockLinkCopyText" id="blockLinkCopyText">
              <a href="https://affpa.top/L?tag=d_1191747m_97c_&site=1191747&ad=97">
                Reclamer les bonus
              </a>
            </div>
          </div>
        )}

        {/* first block footer */}
        <div className="firstblock-footer">
          <div className="row container-fluid ">
            <div className="col-lg-6 col-sm-12 blockLeftFooter">
              <img
                src="/Img/logo-king-fury-pronos.jpg"
                className="logoFooter"
                alt="logo"
              />
              <p className="mt-3 paragraphFooter">
                <b>kingfurypronos.com</b> se distingue comme la référence
                incontestée dans l'univers des paris en ligne. Conçu par une
                équipe chevronnée, notre site regorge de conseils d'experts,
                fruit de longues années d'expertise dans le domaine. Explorez
                notre sélection exclusive des meilleurs bookmakers
                internationaux, accompagnée des offres promotionnelles les plus
                attrayantes
              </p>
              <div className="d-flex socialNetworkFooter">
                <Link
                  className="me-2 linkSocialNetwork"
                  to="https://www.facebook.com/profile.php?id=100089627036229&mibextid=LQQJ4d"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    className="bi bi-facebook iconNetwork"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                  </svg>
                </Link>
                <Link
                  className="me-2 linkSocialNetwork"
                  to="https://www.instagram.com/king_fury_prostic?igsh=MW5xNXc0NG55ajJraQ%3D%3D&utm_source=qr"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    className="bi bi-instagram iconNetwork"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                  </svg>
                </Link>
                <Link
                  className="me-2 linkSocialNetwork"
                  to="https://youtube.com/@KINGFURYPRONO?si=xXwCsnQa1VijnStT"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    className="bi bi-youtube iconNetwork"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                  </svg>
                </Link>
                <Link
                  className="me-2 linkSocialNetwork"
                  to="https://www.tiktok.com/@king_fury_pronos?_t=8lqtphp6LeY&_r=1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    className="bi bi-tiktok iconNetwork"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                  </svg>
                </Link>
                <Link
                  className="me-2 linkSocialNetwork"
                  to="https://t.me/kingfurypronost"
                  style={{ color: "white" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    class="bi bi-telegram"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 blockfooterTopBookmakers">
              <p className="footerTopBookmakers">Top Bookmakers</p>
              <div className="row">
                <div className="col-md-3 me-2 d-flex">
                  <p className="avisBookmakersFooter">Avis 22bet</p>
                  <div className="col-md-6">
                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star empty">&#9733;</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 me-4 d-flex">
                  <p className="me-5 avisBookmakersFooter">Avis 1xbet</p>
                  <div class="rating">
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                  </div>
                </div>
                <div className="col-md-3  d-flex">
                  <p className="me-3 avisBookmakersFooter">Avis Mystake</p>
                  <div class="rating">
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star empty">&#9733;</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 me-2 d-flex">
                  <p className="me-2 avisBookmakersFooter">Avis 888sport</p>
                  <div class="rating">
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star" empty>
                      &#9733;
                    </span>
                    <span class="star" empty>
                      &#9733;
                    </span>
                    <span class="star empty">&#9733;</span>
                  </div>
                </div>
                <div className="col-md-3 me-4 d-flex">
                  <p className="me-3 avisBookmakersFooter">Avis Zodiacbet</p>
                  <div class="rating">
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star" empty>
                      &#9733;
                    </span>
                    <span class="star" empty>
                      &#9733;
                    </span>
                  </div>
                </div>
                <div className="col-md-3  d-flex">
                  <p className="me-4 avisBookmakersFooter">Avis 20Bet</p>
                  <div class="rating">
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star empty">&#9733;</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 me-2 d-flex">
                  <p className="me-3 avisBookmakersFooter">Avis Rolletto</p>
                  <div class="rating">
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star" empty>
                      &#9733;
                    </span>
                    <span class="star empty">&#9733;</span>
                  </div>
                </div>
                <div className="col-md-3 me-4 d-flex">
                  <p className="me-5 avisBookmakersFooter">Avis Bet365</p>
                  <div class="rating">
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star" empty>
                      &#9733;
                    </span>
                    <span class="star" empty>
                      &#9733;
                    </span>
                  </div>
                </div>
                <div className="col-md-3  d-flex">
                  <p className="me-3 avisBookmakersFooter">Avis Librabet</p>
                  <div class="rating">
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star empty">&#9733;</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* last block footer */}
            <div className="lastBlock-Footer">
              <div className="footer-copyright text-center">
                <p className="text-Copyright">
                  © 2024 Copyright: King Fury Pronos. Tous droits réservés.
                  développé par{" "}
                  <Link
                    className="linkAtomTech"
                    target="_blank"
                    to="https://www.atomtechpro.com"
                  >
                    ATOM TECH
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
