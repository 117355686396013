import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../Styles/Header.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import GifOnScroll from "./GifOnScroll";

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="header">
        <header>
          {/* for mobile */}
          <img
            src="Img/1Xbet123.gif"
            className="imageHeaderForMobile"
            alt="logo"
          />

          {/* first line header */}
          <div className="firsLineHeader">
            <div className="container-fluid row">
              <div className="col-md-8 d-flex">
                <form class="d-flex me-2">
                  <input
                    class="form-control me-2 inputSearch"
                    type="search"
                    placeholder="Rechercher..."
                    aria-label="Search"
                  />
                  <button
                    class="btn btn-outline-success btnSearch"
                    type="submit"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>
                  </button>
                </form>
                <Link className="mt-2 me-2 linkBestsBookmakers" href="">
                  Meilleurs bookmakers pour 2024
                </Link>
                <Link className="linkParierXbet" to="https://1xbet.cm/">
                  Parier sur 1xbet
                </Link>
              </div>
              <div className="col-md-4 d-flex justify-content-end">
                <Link
                  className="mt-2 me-5 linkFirstLineHeader"
                  to="/avis-bookmakers"
                >
                  Avis bookmakers
                </Link>
                <Link className="mt-2 linkFirstLineHeader" to="/guide-paris">
                  Guide des paris
                </Link>
              </div>
            </div>
          </div>

          {/* row logo and gif */}
          <div className="blockLogo">
            <div className="container-fluid row d-flex">
              <div className="col-4">
                <Link to="/">
                  <img
                    src="/Img/logo-king-fury-pronos.jpg"
                    className="logoHeader"
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="col-8">
                <img
                  src="/Img/1Xbet123.gif"
                  className="imageGifHeader imageGifHeader2"
                  alt="logo"
                />
              </div>
            </div>
          </div>

          {/* Navbar (menu) */}
          <div
            className={
              isScrolled ? "divNavbarMenu shadowDivNavbarMenu" : "divNavbarMenu"
            }
          >
            <Navbar
              expand="md"
              className="blockMenu"
              style={{ display: "flex" }}
            >
              <Container fluid>
                <div style={{ display: "flex" }}>
                  <Navbar.Toggle
                    className="navbar-toggler me-5"
                    aria-controls=""
                    style={{
                      backgroundColor: "white",
                      height: "50px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      right: "0",
                    }}
                  >
                    {/* Nav mobile */}
                    <a href="/">
                      <img
                        src="/Img/logo-king-fury-pronos.jpg"
                        className="me-4 logoHeader2"
                        alt="logo"
                      />
                    </a>

                    <button className="parierSurMelBet">
                      <a href="https://refpaikgai.top/L?tag=d_2542037m_2170c_&site=2542037&ad=2170">
                        Parier sur Melbet
                      </a>
                    </button>
                    {/* Nav mobile */}
                  </div>
                </div>
                <Navbar.Collapse id="navbarScroll">
                  <Nav className="menu" navbarScroll>
                    <Nav.Link href="/" className="linkMenu">
                      Acceuil
                    </Nav.Link>
                    <Nav.Link
                      href="/classement-bookmakers"
                      className="linkMenu"
                    >
                      Classement bookmaker
                    </Nav.Link>
                    <Nav.Link href="/avis-bookmakers" className="linkMenu">
                      Avis bookmakers
                    </Nav.Link>
                    <NavDropdown
                      title="Les bonus"
                      className="linkMenu"
                      id="navbarScrollingDropdown"
                      rootCloseEvent="mousedown"
                    >
                      <div className="Dropdown-Ul">
                        <NavDropdown.Item
                          className="dropdown-menu1"
                          href="https://affpa.top/L?tag=d_1191747m_70865c_&site=1191747&ad=70865"
                        >
                          Télécharger 1xbet
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="dropdown-menu1"
                          href="https://refpaikgai.top/L?tag=d_2542037m_56537c_&site=2542037&ad=56537"
                        >
                          Télécharger Melbet
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="dropdown-menu1"
                          href="https://bwredir.com/1Fv5"
                        >
                          Télécharger BetWinner
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="dropdown-menu1"
                          href="https://t.me/kingfurypronost/4167"
                        >
                          Télécharger 888Startz
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="dropdown-menu1"
                          href="https://refpaiozdg.top/L?tag=d_3511537m_54987c_&site=3511537&ad=54987"
                        >
                          Télécharger Megapari
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="dropdown-menu1"
                          href="https://lb-aff.com//L?tag=d_2763873m_66803c_apk1&site=2763873&ad=66803"
                        >
                          Télécharger linebet
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="dropdown-menu1"
                          href="https://1wqsg.com/?p=dcrz"
                        >
                          Télécharger 1win
                        </NavDropdown.Item>
                      </div>
                    </NavDropdown>
                    <Nav.Link
                      href="https://t.me/+R1uvDBd4TBU4YzJk"
                      target="_blank"
                      className="linkMenu"
                    >
                      Canal Télégram
                    </Nav.Link>
                    <Nav.Link href="/nouveaux-bookmakers " className="linkMenu">
                      Nouveaux bookmakers
                    </Nav.Link>
                    <NavDropdown
                      title="Plus"
                      className="linkMenu linkMenuPlus"
                      id="navbarScrollingDropdown"
                    >
                      <NavDropdown.Item
                        href="/guide-paris"
                        className="dropdown-menu1"
                      >
                        Guide des paris
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href="https://t.me/+R1uvDBd4TBU4YzJk"
                        target="_blank"
                        className="dropdown-menu1"
                      >
                        Astuces Télégram
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href="https://t.me/lionelpcsbot_bot?start=r06422136925"
                        target="_blank"
                        className="dropdown-menu1"
                      >
                        Gagner de l'argent avec Lionel PCS
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href="https://t.me/kingfury_bot?start=r06422136925"
                        target="_blank"
                        className="dropdown-menu1"
                      >
                        Gagner de l'argent avec King Fury
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        </header>

        {/* gif fixed*/}
        <GifOnScroll />
        {/* gif fixed*/}
      </div>
    </>
  );
}

export default Header;
