import React from "react";
import FirstParahraphHomePage from "./First-Parahraph-HomePage";
import SecondParahraphHomePage from "./Second-Parahraph-HomePage";
import ThirdParahraphHomePage from "./Third-Parahraph-HomePage";
import Newsletter from "../Component-All-Pages/NewsletterForm";
import LastBlockCenterHomePage from "./LastBlockCenter-HomePage";
import "../../Styles/HomePage.css";
import BestBookmakersTable from "../Component-All-Pages/BestBookmakersTable";

function CenterBlockHomePage() {
  return (
    <>
      <div className="CenterBlockHomePage">
        <div
          className="row divReclamerBonusBookmaker"
          id="divReclamerBonusBookmaker"
        >
          <img src="Img/logo/logo1xbet.png" alt="1xbet" />
          <p style={{ color: "white" }}>
            Bonus exclusif de 100% jusqu’à 65 000 XAF
          </p>
          <p id="codePromoDivReclamer">Code promo : Zen142</p>
          <a href="https://affpa.top/L?tag=d_1191747m_97c_&site=1191747&ad=97">
            Obtenir le bonus
          </a>
        </div>
        <FirstParahraphHomePage />
        <BestBookmakersTable />
        <SecondParahraphHomePage />
        <Newsletter />
        <img
          className="imageNewsBookmaker2"
          src="Img/codepromo1.jpeg"
          alt="1xbet"
        />
        <ThirdParahraphHomePage />
        <LastBlockCenterHomePage />
      </div>
    </>
  );
}

export default CenterBlockHomePage;
