import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "../../Styles/HomePage.css";

function LeftBlockHomePage() {
  return (
    <>
      <div className="LeftBlockHomePage">
        <img
          className="imageRightBlokHome"
          id="get-up-to-100"
          src="Img/codepromo5.jpeg"
          alt="11Xbet"
        />
        <div className="blockAccordion">
          <Accordion className="accordion">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordionHeader">
                Évaluation des bookmakers
              </Accordion.Header>
              <Accordion.Body className="textRightBlockHomePage">
                Faire des paris est une chose, mais les placer sur les meilleurs
                bookmakers en est une autre. Chez King-Fury-Pronos, nous
                réalisons des analyses approfondies pour vous guider vers les
                meilleurs sites de paris sportifs, vous offrant ainsi une
                expérience de paris simple et sécurisée. Notre évaluation ne se
                limite pas uniquement aux cotes ; <br /> <br />
                nous examinons également une multitude de critères tels que la
                variété des sports proposés, la qualité du service client, les
                bonus, les options de dépôt et de retrait, les avis des clients
                et même la disponibilité des jeux virtuels. Avec notre approche
                complète, nous demeurons votre meilleure source de conseils en
                matière de bookmakers
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="accordionHeader">
                Bonus
              </Accordion.Header>
              <Accordion.Body>
                Le travail et le professionalisme sont nos règles d’or, c’est
                pourquoi nous vous demandons de nous suivre pour être les plus
                fructueux en paris sportifs. Pour plus de conseils sur les
                bonus, vous pouvez nous rejoindre sur notre page de bonus pour
                avoir plus de détails et de conseils.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="accordionHeader">
                Fiableté
              </Accordion.Header>
              <Accordion.Body>
                Le travail et le professionalisme sont nos règles d’or, c’est
                pourquoi nous vous demandons de nous suivre pour être les plus
                fructueux en paris sportifs. Pour plus de conseils sur les
                bonus, vous pouvez nous rejoindre sur notre page de bonus pour
                avoir plus de détails et de conseils.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <img
          className="imageRightBlokHome"
          id="gifGirl1xbet"
          src="Img/gifGirl1xbet.gif"
          alt="11Xbet"
        />
        <img
          className="imageRightBlokHome"
          id="get-up-to-100"
          src="Img/get-up-to-100.gif"
          alt="11Xbet"
        />
      </div>
    </>
  );
}

export default LeftBlockHomePage;
