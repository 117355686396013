import { Link } from "react-router-dom";
import "../../Styles/BestBookmakersTable.css";

export const BestBookmakersJson = [
  {
    id: 1,
    name: "1xbet",
    logo: "Img/logo/logo1xbet.png",
    note: 5,
    year: 2011,
    code: "Zen142",
    bonus: "200€",
    avis: "",
    lien: "https://affpa.top/L?tag=d_1191747m_97c_&site=1191747&ad=97",
  },
  {
    id: 2,
    name: "melbet",
    logo: "Img/logo/logomelbet.png",
    note: 4,
    year: 2012,
    code: "Zen142",
    bonus: "150€",
    avis: "",
    lien: "https://refpaikgai.top/L?tag=d_2542037m_2170c_&site=2542037&ad=2170",
  },
  {
    id: 3,
    name: "betwinner",
    logo: "Img/logo/logobetwinner.png",
    note: 4,
    year: 2016,
    code: "Zen142",
    bonus: "200€",
    avis: "",
    lien: "https://bwredir.com/1Fv3",
  },
  {
    id: 4,
    name: "linebet",
    logo: "Img/logo/logolinebet.png",
    note: 3,
    year: 2014,
    code: "Xr90",
    bonus: "150€",
    avis: "",
    lien: "https://lb-aff.com//L?tag=d_2763873m_22611c_site&site=2763873&ad=22611&r=registration/",
  },
  {
    id: 5,
    name: "1win",
    logo: "Img/logo/logo1win.png",
    note: 4,
    year: 2019,
    code: "Zen142",
    bonus: "150€",
    avis: "",
    lien: "https://1wnurc.com/#7k9s",
  },
  {
    id: 6,
    name: "Megapari",
    logo: "Img/logo/logomegapari.jpg",
    note: 4,
    year: 2019,
    code: "Zen142",
    bonus: "150€",
    avis: "",
    lien: "https://refpaiozdg.top/L?tag=d_3318471m_77819c_&site=3318471&ad=77819&r=registration",
  },
  {
    id: 7,
    name: "888starz",
    logo: "Img/logo/logo88start.png",
    note: 4,
    year: 2020,
    code: "Zen142",
    bonus: "150€",
    avis: "",
    lien: "https://bonusme.fun/L?tag=d_3275315m_37513c_&site=3275315&ad=37513",
  },
  {
    id: 8,
    name: "Betanyou",
    logo: "Img/logo/logobetandyou.png",
    note: 4,
    year: 2010,
    code: "Xr90",
    bonus: "150€",
    avis: "",
    lien: "https://refpacd.top//L?tag=d_2967515m_63039c_&site=2967515&ad=63039",
  },
  {
    id: 9,
    name: "Helabet",
    logo: "Img/logo/logohelabet.png",
    note: 4,
    year: "",
    code: "hl_3194",
    bonus: "150€",
    avis: "",
    lien: "https://1212fghnna.com/L?tag=d_1793613m_36559c_&site=1793613&ad=36559",
  },
  {
    id: 10,
    name: "Mosbet",
    logo: "Img/logo/logomostbet.png",
    note: 3,
    year: "",
    code: "Xr90",
    bonus: "150€",
    avis: "",
    lien: "https://xp-aff.com///L?tag=d_2560941m_64821c_&site=2560941&ad=64821",
  },
  {
    id: 11,
    name: "Goldpari",
    logo: "Img/logo/logogolden.png",
    note: 5,
    year: 2019,
    code: "Xr90",
    bonus: "200€",
    avis: "",
    lien: "https://gpaff.top/L?tag=d_3319956m_69627c_&site=3319956&ad=69627",
  },
  {
    id: 12,
    name: "Paripulse",
    logo: "Img/logo/logoparipulse.png",
    note: 3,
    year: "",
    code: "Xr90",
    bonus: "150€",
    avis: "",
    lien: "https://refpa8299926.top/L?tag=d_3389449m_64499c_&site=3389449&ad=64499&r=promotions/first-deposit",
  },
  {
    id: 13,
    name: "Kifbet",
    logo: "Img/logo/logokifbet.png",
    note: 3,
    year: "",
    code: "Kifbet ",
    bonus: "100€",
    avis: "",
    lien: "https://kifbet.com/affiliates/?btag=1548632",
  },
  {
    id: 14,
    name: "Afropari",
    logo: "Img/logo/logoAfropari.jpeg",
    note: 4,
    year: "",
    code: "Zen142",
    bonus: "",
    avis: "",
    lien: "https://apaff.top/L?tag=d_3456276m_70055_&site=345627&ad=70055",
  },
  {
    id: 14,
    name: "Lucky stars",
    logo: "",
    note: 3,
    year: "",
    code: "xr90",
    bonus: "",
    avis: "",
    lien: "https://luckyhqu.com/v3/1968/gods#bppj",
  },
];

function BestBookmakersTable() {
  return (
    <>
      {/* tableau des bookmakers */}
      <div className="BestBookmakersTable">
        <h5 className="tiltleBestBookmakers">TOP BOOKMAKERS PAR K-F-P</h5>
        <table className="tablebestbookmaker">
          <thead>
            <tr className="trbookmakerpremium">
              <th colSpan="7">BOOKMAKERS PREMUIM</th>
            </tr>
            <tr className="trbookmaker">
              <th className="thbookmaker">Bookmakers</th>
              {/* <th className="thbookmaker">Note</th> */}
              <th className="thbookmaker">Code promo</th>
              <th className="thbookmaker">Bonus</th>
              <th className="thbookmaker">Année</th>
              <th className="thbookmaker">Avis</th>
              <th className="thbookmaker">Obtenir le bonus</th>
            </tr>
          </thead>
          <tbody>
            {BestBookmakersJson.map((bookmaker) => (
              <tr className="trEveryBookmaker" key={bookmaker.id}>
                <td className="tdEveryBookmaker logoBookmakerTable">
                  <img
                    className="logoBookmakerTable"
                    src={bookmaker.logo}
                    alt={bookmaker.name}
                  />
                </td>
                {/* <td className="noteBookmakertable"></td> */}
                <td className="codePromoBookmakerTable">{bookmaker.code}</td>
                <td className="bonusBookmakerTable">{bookmaker.bonus}</td>
                <td className="yearBookmakerTable">{bookmaker.year}</td>
                <td className="avisBookmakerTable">
                  <Link className="linkAvisBookmakerTable" to={bookmaker.lien}>
                    Avis
                  </Link>
                </td>
                <td className="consulterBookmakerTable">
                  <Link
                    className="linkConsulterBookmakerTable"
                    to={bookmaker.lien}
                  >
                    Obtenir
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default BestBookmakersTable;
