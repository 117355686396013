import { React, useEffect } from "react";
import Header from "../Component-All-Pages/Header";
import Footer from "../Component-All-Pages/Footer";
import "../../Styles/Paragraph-HomePage.css";
import "../../Styles/ClassementBookmakerPage.css";
import RightBlockHomePage from "../Component-HomePage/RightBlock-HomePage";
import LeftBlockHomePage from "../Component-HomePage/LeftBlock-HomePage";
import BestBookmakersTable, {
  BestBookmakersJson,
} from "../Component-All-Pages/BestBookmakersTable";
import { Link } from "react-router-dom";
import Newsletter from "../Component-All-Pages/NewsletterForm";
import LastBlockCenterHomePage from "../Component-HomePage/LastBlockCenter-HomePage";

function ClassementBookmakerPage() {
  useEffect(() => {
    // titre de la page
    document.title = "King Fury Pronos - Classements des bookmakers";
  }, []);
  return (
    <div className="ClassementBookmakerPage">
      <Header />
      <div className="container-fluid bodyHomePage">
        <div className="row">
          <div className="col-md-3">
            <LeftBlockHomePage />
          </div>
          <div className="col-md-6 centerBlockPage">
            <h2 className="titleHomePage">Classements des bookmakers</h2>
            <p className="textHomePage">
              Bienvenue sur kingfurypronos. Si vous êtes novice dans le monde
              des paris sportifs, vous avez probablement constaté qu'il n'est
              pas simple de trouver un bon bookmaker parmi la multitude d'offres
              sur le marché, dont la fiabilité peut varier.
            </p>
            <p className="textHomePage">
              Alors, comment dénicher le meilleur parmi eux ? Comment distinguer
              les authentiques des imposteurs ? Voilà autant de questions qui
              peuvent vous préoccuper. Heureusement, deux solutions s'offrent à
              vous.
            </p>
            <h2 className="titleHomePage">
              Synthèse du Classement Actuel des Meilleurs Bookmakers
            </h2>
            <p className="textHomePage">
              Découvrez ici des évaluations détaillées des bookmakers les plus
              fiables et sécurisés dans le monde des paris sportifs en ligne.
              Nous vous fournissons également une gamme complète d'informations
              sur les promotions, les politiques de transfert d'argent et les
              meilleurs bonus disponibles.
            </p>
            <p className="textHomePage">
              Notre équipe chez King-Fury-Pronos est constituée de parieurs
              professionnels chevronnés, dédiés à vous fournir des conseils
              pratiques sur les sites de jeux en ligne, ainsi que des outils de
              paris et des stratégies pour améliorer votre expérience de jeu.
              Profitez également de notre communauté en plein essor pour évaluer
              les bookmakers, partager vos expériences et comparer les
              différents sites grâce à notre outil de comparaison unique. Si
              vous avez des questions ou souhaitez bénéficier de nos offres
              exclusives, n'hésitez pas à contacter notre service client
              expérimenté.
            </p>
            <div className="blockCardBookmaker container-fluid">
              <div className="row">
                {BestBookmakersJson.map((boolmaker) => (
                  <div
                    className="col-md-4 mb-2  cardBookmaker"
                    key={boolmaker.id}
                  >
                    <img
                      className="imageCardBookmaker"
                      src={boolmaker.logo}
                      alt={boolmaker.name}
                    />
                    <p className="titleCardBookmaker">
                      Promotions de Bienvenue Généreuses
                    </p>
                    <p className="titleCodePromoCardBookmaker">Code promo</p>
                    <p className="codePromoCardBookmaker">{boolmaker.code}</p>
                    <Link className="linkCardBookmaker" to={boolmaker.lien}>
                      Soliciter les Bonus
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            <BestBookmakersTable />
            <Newsletter />
            <LastBlockCenterHomePage />
          </div>
          <div className="col-md-3">
            <RightBlockHomePage />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ClassementBookmakerPage;
