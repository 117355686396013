import "../../Styles/LastBlockCenter-HomePage.css";

function LastBlockCenterHomePage() {
  return (
    <>
      <div className="LastBlockCenterHomePage">
        <div className="row">
          <div className="col-md-4 lastBlockCenter">
            <img
              className="imageLastBlockCenter"
              src="/Img/guide-850.png"
              alt="guide"
            />
            <h6 className="titleLastBlock">Guide des paris sur le football</h6>
            <p className="textLastBlock">
              Les paris sur le football sont vraiment le roi de la pêche
              sportive. Il revendique la part du lion sur le montant total des
              paris placés, un stupéfiant 60%.
            </p>
          </div>
          <div className="col-md-4 lastBlockCenter">
            <img
              className="imageLastBlockCenter"
              src="/Img/ASIAN_HANDICAP.png"
              alt="guide"
            />
            <h6 className="titleLastBlock">
              Guide détaillé des paris sur les handicaps asiatiques
            </h6>
            <p className="textLastBlock">
              Pourquoi les cotes du handicap asiatique sont plus élevées, quels
              sont les paris AH les plus courants et quels bookmakers proposent
              des paris asiatiques.
            </p>
          </div>
          <div className="col-md-4 lastBlockCenter">
            <img
              className="imageLastBlockCenter"
              src="/Img/ballon.jpg"
              alt="guide"
            />
            <h6 className="titleLastBlock">Paris sur le football de valeur</h6>
            <p className="textLastBlock">
              Apprenez à décoder la manière dont les cotes sont définies pour
              maximiser vos chances de succès
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default LastBlockCenterHomePage;
