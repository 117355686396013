import React from "react";
import "../../Styles/NewsletterForm.css";

function Newsletter() {
  return (
    <div className="form-container">
      <div className="form-content">
        <h3 className="form-title">
          Abonnez-vous pour recevoir des pronostics gratuitement
        </h3>
        <form>
          <label className="emailNewsletterLabel" htmlFor="email">
            Adresse email:
          </label>
          <input
            type="text"
            className="emailNewsletterInput"
            id="email"
            name="email"
          />

          <div className="checkbox-container">
            <input type="checkbox" id="accept" name="accept" />
            <label htmlFor="accept">
              Oui, j'accepte de recevoir des e-mails de kingfurypronos.com
            </label>
          </div>

          <button className="buttonSubmitNewsletter" type="submit">
            S'ABONNER
          </button>
        </form>
      </div>
      <div className="image-container">
        <img src="/Img/NewsletterForm.png" name="img" />
      </div>
    </div>
  );
}

export default Newsletter;
