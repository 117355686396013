import React from "react";
import "../../Styles/HomePage.css";
import Accordion from "react-bootstrap/Accordion";

function RightBlockHomePage() {
  function copyCodeToClipboard(text) {
    text = "Zen142";
    navigator.clipboard
      .writeText(text)
      .then(function () {
        document.getElementById("btnCopyRightBlock").style.display = "none";
        document.getElementById("succesBtnCopyRightBlock").style.display =
          "block";
      })
      .catch(function (error) {});
  }
  return (
    <>
      <div className="RightBlockHomePage">
        {/* block pour copier le code promo */}
        <div className="blockRightCopyCode" id="blockRightCopyCode">
          <img src="Img/logo/logo1xbet2.png" alt="1xbet" />
          <div class="rating">
            <span class="star">&#9733;</span>
            <span class="star">&#9733;</span>
            <span class="star">&#9733;</span>
            <span class="star">&#9733;</span>
            <span class="star">&#9733;</span>
          </div>
          <p>Des offres de bienvenue pour tous</p>
          <p>code bonus</p>
          <div className="divTextToCopy" id="divTextToCopy">
            <input
              className="inputTextToCopy"
              id="inputTextToCopy"
              type="text"
              value="Zen142"
              disabled
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              class="bi bi-copy"
              viewBox="0 0 16 16"
              style={{ color: "white", cursor: "pointer" }}
              onClick={copyCodeToClipboard}
              id="btnCopyRightBlock"
            >
              <path
                fill-rule="evenodd"
                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              class="bi bi-check2-all"
              viewBox="0 0 16 16"
              style={{ color: "gold", display: "none" }}
              id="succesBtnCopyRightBlock"
            >
              <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
              <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
            </svg>
          </div>
          <div className="blockLinkCopyText" id="blockLinkCopyText">
            <a href="https://affpa.top/L?tag=d_1191747m_97c_&site=1191747&ad=97">
              Reclamer les bonus
            </a>
          </div>
        </div>
        {/* block pour copier le code promo */}

        <img
          className="imageRightBlokHome"
          src="Img/bonus-Davido-1xbet.gif"
          alt="11Xbet"
        />
        <h6 className="titleRightBlockHomePage">NOS MEILLEURS BOOKMAKERS</h6>
        <p className="textRightBlockHomePage">
          Sur king-Fury-Pronos, vous pouvez trouver les meilleurs bookmakers en
          ligne et les plus fiables. Il vous est conseillé de vous inscrire et
          de placer vos paris sur nos partenaires premium de confiance. Cliquez
          sur le logo de chaque bookmaker pour lire une critique détaillée, voir
          les commentaires des professionnels et les critiques des autres
          joueurs ou cliquez sur l’option «Tous les bookmakers» pour voir notre
          liste complète des sociétés de paris.
        </p>
        <img
          className="imageRightBlokHome"
          src="Img/codepromo5.jpeg"
          alt="1Xbet"
        />
        <h6 className="titleRightBlockHomePage">Méthodes de payement</h6>
        <p className="textRightBlockHomePage">
          Dans cette section, vous trouverez quels bookmakers autoriseront les
          dépôts via les cartes de crédit, Neteller, Orange Money, Mtn Mobile
          Money, Skrill et Paysafecard. Vous pouvez également afficher des
          informations pour chaque méthode de dépôt et de retrait.
        </p>
        <div className="row">
          <div className="col-md-5">
            <img
              className="imageRightBlokHome"
              src="Img/orangemoney.png"
              alt="1Xbet"
            />
          </div>
          <div className="col-md-7 mt-3">
            <p className="textRightBlockHomePage">
              Bookmakers avec <b>Orange Money</b>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <img
              className="imageRightBlokHome"
              src="Img/mobilemoney.jpeg"
              alt="1Xbet"
            />
          </div>
          <div className="col-md-7 mt-3">
            <p className="textRightBlockHomePage">
              Bookmakers avec <b>Mtn Mobile Money</b>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <img
              className="imageRightBlokHome"
              src="Img/paysafecard.jpeg"
              alt="1Xbet"
            />
          </div>
          <div className="col-md-7 mt-3">
            <p className="textRightBlockHomePage">
              Bookmakers avec <b>Paysafecard</b>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <img
              className="imageRightBlokHome"
              src="Img/skrill.png"
              alt="1Xbet"
            />
          </div>
          <div className="col-md-7 mt-3">
            <p className="textRightBlockHomePage">
              Bookmakers avec <b>Skrill</b>
            </p>
          </div>
        </div>
        <h6 className="titleRightBlockHomePage">Êtes-vous informé ?</h6>
        <div className="blockAccordion" style={{ zIndex: "1" }}>
          <Accordion className="accordion" style={{ zIndex: "1" }}>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordionHeader">
                Évaluation des bookmakers
              </Accordion.Header>
              <Accordion.Body className="textRightBlockHomePage">
                Faire des paris est une chose, mais les placer sur les meilleurs
                bookmakers en est une autre. Chez King-Fury-Pronos, nous
                réalisons des analyses approfondies pour vous guider vers les
                meilleurs sites de paris sportifs, vous offrant ainsi une
                expérience de paris simple et sécurisée. Notre évaluation ne se
                limite pas uniquement aux cotes ; <br /> <br />
                nous examinons également une multitude de critères tels que la
                variété des sports proposés, la qualité du service client, les
                bonus, les options de dépôt et de retrait, les avis des clients
                et même la disponibilité des jeux virtuels. Avec notre approche
                complète, nous demeurons votre meilleure source de conseils en
                matière de bookmakers
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="accordionHeader">
                Bonus
              </Accordion.Header>
              <Accordion.Body>
                Le travail et le professionalisme sont nos règles d’or, c’est
                pourquoi nous vous demandons de nous suivre pour être les plus
                fructueux en paris sportifs. Pour plus de conseils sur les
                bonus, vous pouvez nous rejoindre sur notre page de bonus pour
                avoir plus de détails et de conseils.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="accordionHeader">
                Fiableté
              </Accordion.Header>
              <Accordion.Body>
                Le travail et le professionalisme sont nos règles d’or, c’est
                pourquoi nous vous demandons de nous suivre pour être les plus
                fructueux en paris sportifs. Pour plus de conseils sur les
                bonus, vous pouvez nous rejoindre sur notre page de bonus pour
                avoir plus de détails et de conseils.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <img
          className="imageRightBlokHome"
          id="gifGirl1xbet"
          src="Img/gifGirl1xbet.gif"
          alt="1Xbet"
        />
        <img
          className="imageRightBlokHome"
          id="get-up-to-100"
          src="Img/codepromo6.jpeg"
          alt="1Xbet"
        />
      </div>
    </>
  );
}

export default RightBlockHomePage;
