import { useEffect, useState } from "react";
import "../../Styles/GifOnScroll.css";

function GifOnScroll() {
  const [showImage, setShowImage] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const distanceToBottom = documentHeight - (scrollTop + windowHeight);

      // Ajustez cette valeur en fonction de la proximité souhaitée avant que l'image disparaisse
      const threshold = 100;

      if (distanceToBottom <= threshold) {
        setShowImage(false); // Masque l'image lorsque l'utilisateur est proche du bas de la page
      } else {
        setShowImage(true); // Affiche l'image dans les autres cas
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="gifOnScroll">
      {showImage && (
        <img src="Img/1xbet.gif" className="gifOnScroll" alt="gifOnScroll" />
      )}
    </div>
  );
}

export default GifOnScroll;
