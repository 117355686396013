import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../Styles/App.css";
import HomePage from "./Pages/HomePage";
import ClassementBookmakerPage from "./Pages/ClassementBookmakerPage";
import AvisBookmakerPage from "./Pages/AvisBookmakerPage";
import NouveauxBookmakerPage from "./Pages/nouveauxBookmakerPage";
import BonusBookmaker from "./Pages/BonusBookmakerPage";
import BonusMobile from "./Pages/BonusMobilePage";
import BonusRechargement from "./Pages/BonusRechargementPage";
import BonusFidelite from "./Pages/BonusFidelite";
import GuideParis from "./Pages/GuideParis";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/classement-bookmakers"
            element={<ClassementBookmakerPage />}
          />
          <Route path="/avis-bookmakers" element={<AvisBookmakerPage />} />
          <Route
            path="/nouveaux-bookmakers"
            element={<NouveauxBookmakerPage />}
          />
          {/* <Route path="/bonus-bookmakers" element={<BonusBookmaker />} />
          <Route path="/bonus-mobile" element={<BonusMobile />} />
          <Route path="/bonus-rechargement" element={<BonusRechargement />} />
          <Route path="/bonus-fidelité" element={<BonusFidelite />} /> */}
          <Route path="/guide-paris" element={<GuideParis />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
